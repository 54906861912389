import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import '../styles/RealTimeNews.css';

const RealTimeNews = () => {
  const [news, setNews] = useState([]);

  const fetchNews = async () => {
    try {
      console.log('Fetching news...');
      const response = await axios.get(`https://newsapi.org/v2/everything?q=plagas+desinfección&sortBy=publishedAt&apiKey=1a26ea7bc9074372b65c88dcd65dd037`);
      console.log('News fetched:', response.data.articles);

      const newArticles = response.data.articles;

      // Si ya hay 4 noticias, elimina la más antigua antes de agregar la nueva
      setNews(prevNews => {
        const updatedNews = [...prevNews, ...newArticles];
        return updatedNews.slice(-4); // Mantén solo las 4 noticias más recientes
      });

    } catch (error) {
      console.error('Error fetching the news:', error);
    }
  };

  useEffect(() => {
    fetchNews(); // Fetch news immediately when the component mounts

    const interval = setInterval(() => {
      fetchNews();
    }, 24 * 60 * 60 * 1000); // Set interval to 24 hours

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div className="real-time-news">
      <h4>Últimas Noticias</h4>
      {news.map((article, index) => (
        <Card key={index} className="news-article mb-3">
          <Card.Img variant="top" src={article.urlToImage || 'https://via.placeholder.com/150'} />
          <Card.Body>
            <Card.Title>{article.title}</Card.Title>
            <Card.Text>{article.description}</Card.Text>
            <a href={article.url} target="_blank" rel="noopener noreferrer">Leer más</a>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default RealTimeNews;
